<template>
  <div>
    <div>
      <!-- 规格 -->

      <div class="flex align-start">
        <div class="pdr40 edit_field flexcc bold size16">
          商品属性
        </div>
        <div class="pdl40" style="width: 100%">
          <el-table :data="parameterForm.sku" ref="sku" v-if='parameterForm.sku'>
            <!-- <el-table-column prop="id" label="序号" width="50" type="index" align="center" :index="(index) => index + 1" /> -->
            <!-- <el-table-column prop="id" label="序号" width="50" type="index" align="center" /> -->
            <!-- <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand flex-column mrl10" label-width="120px">
                  <div class="size16 bold">—活动配置—</div>
                  <el-form-item label="总价（优惠价）">
                    <el-input v-model="propss.row.startip" style="width: 200px;" placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="定金（优惠价）">
                    <el-input v-model="props.row.startip" style="width: 200px;" placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="活动时间">
                    <el-date-picker v-model="props.row.startip" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                  <div class="size16 bold mrt10">—分润规则—</div>
                  <el-form-item label="总价（优惠价）">
                    <el-input v-model="props.row.startip" style="width: 200px;" placeholder=""></el-input>
                    <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="profitVisible = true">新增分润规则</el-button>
                  </el-form-item>
                  <div class="size16 bold mrt10">—规格图片—</div>
                </el-form>
              </template>
            </el-table-column> -->

            <el-table-column prop="type" label="规格" align="center">
              <template slot-scope="scope">
                <el-checkbox label="全款" v-model="scope.row.type" :true-label='0' :false-label="1"></el-checkbox>
                <!-- <el-checkbox label="定金" :true-label='1'></el-checkbox> -->
              </template>
            </el-table-column>
            <el-table-column prop="name" label="规格名称" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name" placeholder="" clearable></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="总价" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.price" placeholder="" clearable></el-input>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="total_amount" label="定金" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.item.total_amount" placeholder="" clearable></el-input>
              </template>
            </el-table-column> -->
            <!-- <el-table-column prop="one_price" label="第一次补款" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.item.one_price" placeholder="" clearable></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="two_price" label="第二次补款" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.item.two_price" placeholder="" clearable></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="three_price" label="第三次补款" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.item.three_price" placeholder="" clearable></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="four_price" label="第四次补款" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.item.four_price" placeholder="" clearable></el-input>
              </template>
            </el-table-column> -->
            <el-table-column prop="stock" label="库存" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.stock" placeholder="" clearable></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <div style="display: flex">
                  <el-button type="danger" @click="delDataBase( scope.row,scope.$index)" icon="el-icon-delete" circle></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="mrt20">
            <el-button @click="addDataBase" size="small" type="primary" style="width: 100px; margin-top: 20px;">添加规格</el-button>
          </div>
        </div>
      </div>

      <!-- 商品参数 -->
      <div class="flex align-start mrt40">
        <div class="pdr40 edit_field flexcc bold size16 white-space">
          商品参数
        </div>
        <!-- 规格 -->
        <div class="pdl40">
        </div>
        <div class="parameter_box">
          <el-form ref="parameterForm" :model="parameterForm" label-width="120px">
            <el-form-item label="原著">
              <el-select v-model="parameterForm.original_id" placeholder="请选择商品原著" filterable>
                <el-option v-for="(item,i) in original" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="IP">
              <el-select v-model="parameterForm.ip_id" placeholder="请选择商品人物IP" filterable>
                <el-option v-for="(item,i) in figure" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="原创">
              <el-select v-model="parameterForm.achieve_id" placeholder="请选择商品人物IP" filterable>
                <el-option v-for="(item,i) in Original" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="originalVisible = true">新增原创</el-button>
            </el-form-item>
            <el-form-item label="作品尺寸（cm）">
              <div class="flex align-center">
                长： <el-input-number v-model="parameterForm.length" :step="1" :min="0" :max="1000000000" label="请输入"></el-input-number>
                宽： <el-input-number v-model="parameterForm.width" :step="1" :min="0" :max="1000000000" label="请输入"></el-input-number>
                高： <el-input-number v-model="parameterForm.height" :step="1" :min="0" :max="1000000000" label="请输入"></el-input-number>
              </div>
            </el-form-item>
            <el-form-item label="人物比例">
              <el-select v-model="parameterForm.ratio_id" placeholder="请选择人物比例" filterable>
                <el-option v-for="(item,i) in RatioData" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="ratioVisible = true">新增人物比例</el-button>
            </el-form-item>
            <el-form-item label="摆放类型">
              <el-select v-model="parameterForm.put_ids" placeholder="请选择摆放类型" multiple filterable @change='astrictLength1'>
                <el-option v-for="(item,i) in PutData" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="putVisible = true">新增摆放类型</el-button>
            </el-form-item>
            <el-form-item label="作品材质">
              <el-select v-model="parameterForm.material_ids" placeholder="请选择作品材质" multiple filterable @change='astrictLength'>
                <el-option v-for="(item,i) in MaterialData" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="textureVisible = true">新增作品材质</el-button>
            </el-form-item>
            <el-form-item label="全球限量（体）">
              <el-input-number v-model="parameterForm.world_limit" :step="1" :min="0" :max="1000000000" label="请输入"></el-input-number>
            </el-form-item>

            <!--  -->
            <el-form-item label="销售价格">
              <el-input-number v-model="parameterForm.sale_price" :step="1" :min="0" :max="1000000000" label="请输入"></el-input-number>
            </el-form-item>
            <el-form-item label="预售价格">
              <el-input-number v-model="parameterForm.presale_price" :step="1" :min="0" :max="1000000000" label="请输入"></el-input-number>
            </el-form-item>
            <el-form-item label="定金">
              <el-input-number v-model="parameterForm.deposit_price" :step="1" :min="0" :max="1000000000" label="请输入"></el-input-number>
            </el-form-item>

            <!--  -->
            <el-form-item label="重量（KG）">
              <el-input-number v-model="parameterForm.weight" label="请输入" :step="1" :min="0" :max="1000000000"></el-input-number>
            </el-form-item>
            <el-form-item label="出货日期">
              <el-input v-model="parameterForm.shipment_date" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item label="包装方式">
              <el-input v-model="parameterForm.package" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item label="厂家">
              <el-input v-model="parameterForm.factory" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item label="作者">
              <el-select v-model="parameterForm.author_id" placeholder="请选择商品人物IP">
                <el-option v-for="(item,i) in AuthorData" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="authorVisible = true">新增作者</el-button>
            </el-form-item>
            <el-form-item label="自定义参数">
              <div class="flex align-center mrb20" v-for="(item,index) in parameterForm.define_attr" v-if="parameterForm.define_attr">
                属性 <el-input v-model="item.name" placeholder="请输入内容" style="width: 200px;margin-left: 10px;"></el-input>
                值 <el-input v-model="item.value" placeholder="请输入内容" style="width: 200px; margin-left: 10px;"></el-input>
                <div class="mrl20">
                  <el-button type="danger" icon="el-icon-delete" @click="deletecustom(index)" circle></el-button>
                </div>
              </div>
              <el-button @click="addcustom" size="small" type="primary" style="width: 100px; margin-top: 20px;">添加参数</el-button>
              <div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <!-- 新增原创 -->
      <el-dialog title="新增原创" :visible.sync="originalVisible" width="40%">
        <el-form ref="originaForm" :model="originaForm" label-width="100px">
          <el-form-item label="名称" required>
            <el-input v-model="originaForm.name"></el-input>
          </el-form-item>
          <el-form-item label="简介" required>
            <el-input type="textarea" v-model="originaForm.introduce" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <file-pic :file='originaForm.cover' @input='originaForm.cover = $event'></file-pic>
          </el-form-item>
          <el-form-item label="排序" required>
            <el-input v-model="originaForm.sort"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="originalVisible = false">取 消</el-button>
          <el-button type="primary" @click="originalAdd()">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 新增人物比例 -->
      <el-dialog title="新增人物比例 " :visible.sync="ratioVisible" width="30%">
        <el-form ref="ratioForm" :model="ratioForm" label-width="100px">
          <el-form-item label="比例" required>
            <el-input v-model="ratioForm.name"></el-input>
          </el-form-item>
          <el-form-item label="排序" required>
            <el-input v-model="ratioForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="ratioForm.status" placeholder="请选择状态" clearable>
              <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="ratioVisible = false">取 消</el-button>
          <el-button type="primary" @click="RatioAdd()">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 新增摆放类型 -->
      <el-dialog title="新增摆放位置" :visible.sync="putVisible" width="40%">
        <el-form ref="putForm" :model="putForm" label-width="100px">
          <el-form-item label="位置" required>
            <el-input v-model="putForm.name"></el-input>
          </el-form-item>
          <el-form-item label="排序" required>
            <el-input v-model="putForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="putForm.status" placeholder="请选择状态" clearable>
              <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="putVisible = false">取 消</el-button>
          <el-button type="primary" @click="PutAdd()">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 新增作品材质 -->
      <el-dialog title="新增材质" :visible.sync="textureVisible" width="40%">
        <el-form ref="textureForm" :model="originaForm" label-width="100px">
          <el-form-item label="材质名称" required>
            <el-input v-model="textureForm.name"></el-input>
          </el-form-item>
          <el-form-item label="排序" required>
            <el-input v-model="textureForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="textureForm.status" placeholder="请选择状态" clearable>
              <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="textureVisible = false">取 消</el-button>
          <el-button type="primary" @click="MaterialAdd()">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 新增作者 -->
      <el-dialog title="新增作者" :visible.sync="authorVisible" width="40%">
        <el-form ref="authorForm" :model="authorForm" label-width="100px">
          <el-form-item label="作者名称" required>
            <el-input v-model="authorForm.name"></el-input>
          </el-form-item>
          <el-form-item label="作者履历" required>
            <el-input type="textarea" v-model="authorForm.introduce" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
            <div style="color:red">用逗号(,)隔开 展示多段履历</div>
          </el-form-item>
          <el-form-item label="作者图片">
            <file-pic :file='authorForm.cover' @input='authorForm.cover = $event'></file-pic>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="authorVisible = false">取 消</el-button>
          <el-button type="primary" @click="AuthorAdd()">确 定</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import { statusOption, statusDetail } from "@/utils/statusData";
import filePic from "@/components/common/filePic";
export default {
  components: { filePic },
  props: {},
  data() {
    return {
      statusDetail,
      active: "0",
      originalVisible: false,
      ratioVisible: false,
      putVisible: false,
      textureVisible: false,
      authorVisible: false,
      pullDown: { status: 2, limit: 1000 },
      original: [],
      figure: [],
      Original: [],
      AuthorData: [],
      MaterialData: [],
      RatioData: [],
      PutData: [],
      // 表格值
      parameterForm: {
        sku: [], //商品属性
        active: "",
        original_id: "",
        ip_id: "",
        achieve_id: "",
        length: "",
        width: "",
        height: "",
        ratio_id: "",
        put_ids: [],
        world_limit: "",
        sale_price: "",
        presale_price: "",
        deposit_price: "",
        weight: "",
        shipment_date: "",
        package: "",
        factory: "",
        author_id: "",
        material_ids: [],
        define_attr: [
          {
            value: "",
            name: "",
          },
        ],
      },
      originaForm: {
        name: "",
        introduce: "",
        cover: "",
        sort: "",
      },
      textureForm: {
        name: "",
        sort: "",
        status: "",
      },
      ratioForm: {
        name: "",
        status: "",
        sort: "",
      },
      putForm: {
        name: "",
        status: "",
        sort: "",
      },
      authorForm: {
        id: "",
        name: "",
        introduce: "",
        cover: "",
      },
    };
  },

  created() {
    this.getorigina();
    this.getFigure();
    this.getOriginal();
    this.getPut();
    this.getRatio();
    this.getMaterial();
    this.getAuthor();
  },
  mounted() {},
  computed: {},

  methods: {
    astrictLength(val) {
      if (val.length >= 9) {
        this.$message.error("最多可选中9个作品材质");
      }
    },
    astrictLength1(val) {
      if (val.length >= 13) {
        this.$message.error("最多可选中13个摆放位置");
      }
    },
    sonActive() {
      console.log("触发上一步");
      this.active = 1;
      this.$emit("propertyfunction", this.active);
    },
    next() {
      this.parameterForm.active = 3;
      this.$emit("nextStep", this.parameterForm);
    },

    addDataBase() {
      this.parameterForm.sku.push({
        name: "",
        price: "",
        type: "0",
        item: {
          one_price: "",
          two_price: "",
          three_price: "",
          four_price: "",
          total_amount: "",
        },
        stock: "",
      });
    },
    addcustom() {
      this.parameterForm.define_attr.push({
        value: "",
        name: "",
      });
    },
    deletecustom(index) {
      this.parameterForm.define_attr.splice(index, 1);
    },
    delDataBase(row, index) {
      console.log("要删除的index", index);
      this.parameterForm.sku.splice(index, 1);
    },
    // 新增作者
    async AuthorAdd() {
      const data = await this.$Api.animeAuthorEdit(this.authorForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("新增成功");
      this.authorVisible = false;
      this.getAuthor();
      this.authorForm = {
        id: "",
        name: "",
        introduce: "",
        cover: "",
      };
    },
    // 新增材质
    async MaterialAdd() {
      const data = await this.$Api.animeMaterialEdit(this.textureForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("新增成功");
      this.textureVisible = false;
      this.getMaterial();
      this.textureForm = {
        name: "",
        introduce: "",
        cover: "",
        sort: "",
      };
    },
    // 新增摆放位置
    async PutAdd() {
      const data = await this.$Api.animePutEdit(this.putForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("新增成功");
      this.putVisible = false;
      this.getPut();
      this.putForm = {
        name: "",
        status: "",
        sort: "",
      };
    },
    // 新增比例
    async RatioAdd() {
      const data = await this.$Api.animeRatioEdit(this.ratioForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("新增成功");
      this.ratioVisible = false;
      this.getRatio();
      this.ratioForm = {
        name: "",
        status: "",
        sort: "",
      };
    },
    // 新增原创
    async originalAdd() {
      const data = await this.$Api.animeAchieveEdit(this.originaForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("新增成功");
      this.originalVisible = false;
      this.getOriginal();
      this.originaForm = {
        name: "",
        introduce: "",
        cover: "",
        sort: "",
      };
    },
    // 原著
    async getorigina() {
      const { data } = await this.$Api.originalList(this.pullDown);
      this.original = data.list;
    },
    // 人物
    async getFigure() {
      const { data } = await this.$Api.animeIP(this.pullDown);
      this.figure = data.list;
    },
    // 原创
    async getOriginal() {
      const { data } = await this.$Api.animeAchieve(this.pullDown);
      this.Original = data.list;
    },
    // 比例
    async getRatio() {
      const { data } = await this.$Api.animeRatio(this.pullDown);
      this.RatioData = data.list;
    },
    // 摆放
    async getPut() {
      const { data } = await this.$Api.animePut(this.pullDown);
      this.PutData = data.list;
    },
    // 材质
    async getMaterial() {
      const { data } = await this.$Api.animeMaterial(this.pullDown);
      this.MaterialData = data.list;
    },
    // 作者
    async getAuthor() {
      const { data } = await this.$Api.animeAuthor(this.pullDown);
      this.AuthorData = data.list;
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_field {
  border-right: 1px solid black;
  height: 40px;
}
.parameter_box {
  border: 1px solid #b3b5ba;
  width: 100%;
  padding: 20px;
}
</style>