<template>
  <div class="bg-white pd20 box-shadow">
    <div class="schedule_box mrb40">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="选择商品分类"></el-step>
        <el-step title="填写商品信息"></el-step>
        <el-step title="填写商品属性"></el-step>
        <el-step title="填写配置信息"></el-step>
      </el-steps>
    </div>
    <!-- 选择商品分类 -->

    <div v-if="active== 0">
      <div class="flex start">
        <div class="pdr40 edit_field flexcc bold size16">
          选择分类
        </div>
        <div class=" pdl40">
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="商品分类：" required>
              <el-select v-model="form.category_ids" placeholder="请选择" multiple filterable @change="astrictLength">
                <el-option v-for="(item,i) in classifyData" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-button icon="el-icon-plus" style="margin-left: 20px;" @click=" ClassifyVisible = true">新增分类</el-button>
            </el-form-item>
            <el-form-item label="商品类型：" required>
              <el-radio-group v-model="form.anime_type">
                <el-radio :label="1">现货商品</el-radio>
                <!-- <el-radio :label="2">预售商品中</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <!-- 第一 -->
      <!-- <div class="flexcc">
        <el-button type="primary" style="margin-top: 12px;" @click="next">下一步，填写商品信息</el-button>
      </div> -->

      <!-- 第二 -->

    </div>

    <!-- 填写商品信息 -->
    <Info v-show="active== 1" @activefunction="meetfunction" @nextfunction="meetfunction2" ref="info"></Info>

    <!-- 商品属性 -->
    <property v-show="active== 2" @propertyfunction="propertyfunction" @nextStep="nextStep" ref="property"></property>

    <!-- 商品配置 -->
    <configuration v-show="active== 3" @activefunction='activefunction' @nextAccomplish="nextAccomplish" @nextFourForm="nextFourForm" ref="configuration"></configuration>
    <div class="flexcc">
      <!-- <el-button style="margin-top: 12px;" @click="">保存草稿</el-button> -->
      <el-button style="margin-top: 12px;" @click="sonActive()" v-show="active !== 0">上一步</el-button>
      <el-button type="primary" style="margin-top: 12px;" @click="next()" v-show="active !== 3">下一步</el-button>
      <el-button type="primary" style="margin-top: 12px;" @click="confirm" v-show="active== 3">完成，提交商品</el-button>
    </div>

    <!-- 新增分类 -->
    <el-dialog title="新增分类" :visible.sync="ClassifyVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="分类名" required>
          <el-input v-model="classify.name" placeholder="请输入分类名"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="classify.sort" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="classify.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ClassifyVisible = false">取 消</el-button>
        <el-button type="primary" @click="addClassify">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Info from "./Info.vue";
import property from "./property.vue";
import configuration from "./configuration.vue";
import { statusOption, statusDetail } from "@/utils/statusData";

export default {
  components: { Info, property, configuration },
  props: {},
  data() {
    return {
      editId: "",
      ClassifyVisible: false,
      active: 0,
      goodsVlaue: "",
      statusDetail,
      pullDown: { status: 2, limit: 1000 },
      form: {
        category_ids: [],
        anime_type: "",
      },
      classify: {
        name: "",
        sort: "",
        status: "",
      },
      classifyData: [],
    };
  },

  created() {
    this.getClassify();
    this.getEditList();
    console.log("父路由传子的", this.$route.query.id);
  },
  mounted() {},
  computed: {},

  methods: {
    astrictLength(val) {
      if (val.length >= 11) {
        this.$message.error("最多可选中11个商品分类");
      }
    },
    upData(form, formData) {
      // console.log("表单值", formData);
      Object.keys(formData).forEach((item) => {
        // console.log(item);
        formData[item] = form[item];
      });
      // console.log("复制的值", formData);
    },
    // 编辑回显
    async getEditList() {
      if (this.$route.query.id) {
        let id = this.$route.query.id;
        const { data } = await this.$Api.goodsDetail({ id });
        let formData = { ...data, ...data.item };
        let {
          content,
          material_ids,
          define_attr,
          column_ids,
          status_end_time,
          status_start_time,
          chart_img,
          put_ids,
          delivery_type,
          delivery_price,
          status,
          type,
        } = formData;

        // console.log("data详情列表", data);
        // console.log("合并123", formData);

        this.editId = data.id;
        // console.log("最后合并");

        formData.category_ids = formData.category_ids.map((item) => item.id);
        // console.log("最后合并1");

        let chartImg = chart_img ? JSON.parse(chart_img) : [];
        // console.log("解析的图片", chartImg);
        formData.chart_img = chartImg.map((i) => {
          return {
            url: i,
          };
        });
        let contentSrc = content == "[]" ? [] : JSON.parse(content);

        formData.content = contentSrc.map((i) => {
          return {
            url: i,
          };
        });

        // formData.chart_img = [];
        // formData.content = [];
        formData.define_attr = define_attr
          ? define_attr
          : [{ name: "", value: "" }];
        formData.timeValue = [status_start_time, status_end_time];
        formData.label_ids = formData.label_ids.map((item) => item.id);
        formData.material_ids = material_ids.map((item) => item.id);
        formData.put_ids = put_ids.map((item) => item.id);
        formData.column_ids = column_ids
          ? column_ids?.map((item) => item.id)
          : [];
        // console.log(column_ids, "column_ids");
        // console.log(formData, "formData");
        formData.delivery_type = delivery_type.toString();
        this.upData(formData, this.form);
        // console.log("第一个表单", this.form);

        this.upData(formData, this.$refs.info.form);
        // console.log("第二个表单", this.$refs.info.form);

        this.upData(formData, this.$refs.property.parameterForm);
        // console.log("第三个表单", this.$refs.property.parameterForm);

        this.upData(formData, this.$refs.configuration.form);
        // console.log("第四个表单", this.$refs.configuration.form);
      }
    },
    sonActive() {
      this.active--;
    },
    next() {
      // if (this.active++ > 2) this.active = 0;
      this.active++;
      // console.log("第一页输入的值", this.form);
    },
    // 分类
    async getClassify() {
      const { data } = await this.$Api.animeCategory(this.pullDown);
      this.classifyData = data.list;
    },
    // 新增分类
    async addClassify() {
      // console.log("新增管理", this.classify);
      const data = await this.$Api.animeCategoryEdit(this.classify);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.getClassify();
      this.$message.success("新增成功");
      this.ClassifyVisible = false;
      this.classify = {
        name: "",
        sort: "",
        status: "",
      };
    },
    meetfunction(data) {
      this.active = data;
    },
    meetfunction2(data) {
      this.active = data;
    },
    propertyfunction(data) {
      this.active = data;
    },
    nextStep(data) {
      this.active = data;
    },
    //第四页
    activefunction(data) {
      this.active = data;
    },
    nextAccomplish(data) {
      this.active = data;
      console.log(" data.active", data);
    },
    nextFourForm() {},
    async confirm() {
      let form2 = this.$refs.info.form;
      let form3 = this.$refs.configuration.form;
      let form4 = this.$refs.property.parameterForm;
      // form4.sku.forEach((i) => {
      //   if (typeof i.type == "boolean") {
      //     console.log("布尔值的");
      //     i.type = i.type ? 0 : "";
      //   }
      // });
      //   form4.sku
      // Object.assign(this.form, form2, form3, form4);
      // (this.form
      // form.chart_img
      // form2.chart_img = [];
      console.log("表单2", form4);
      console.log("编辑提交的表单", {
        ...this.form,
        ...form2,
        ...form3,
        ...form4,
        id: this.editId,
      });

      form4.sku = form4.sku.map((item) => {
        delete item?.item?.id;
        console.log("前面item", item);
        console.log("旧item.item", item.item);
        return { ...item.item, ...item };
      });
      console.log("form4.sku", form4.sku);

      console.log(" 旧form2.content", form2.content);
      let content = form2.content.map((i) => i.url);
      console.log(" 新content", content);
      let chart_img = form2.chart_img.map((i) => i.url);

      const data = await this.$Api.animeGoodsEdit({
        ...this.form,
        ...form2,
        ...form3,
        ...form4,
        content: content,
        chart_img: chart_img,
        id: this.editId,
      });
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      console.log("新增成功data", data);
      this.$message.success("提交成功");
      this.$router.push({
        path: "/shop/commodity",
        // query: { id: id },
      });
      // configuration property
      // 第二个表单值
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_field {
  border-right: 1px solid black;
  height: 40px;
}
</style>