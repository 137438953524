<template>
  <div>
    <div class="flex align-start">
      <div class="pdr40 edit_field flexcc bold size16">
        配送信息
      </div>
      <div class=" pdl40 flex align-center">
        <div class="mrr20">配送费</div>
        <div>
          <el-radio v-model="form.delivery_type" label="1" @input="deliveryClick">包邮</el-radio>
          <el-radio v-model="form.delivery_type" label="2">到付</el-radio>
          <el-radio v-model="form.delivery_type" label="3">统一邮费</el-radio>
          <el-input-number v-model="form.delivery_price" :disabled="form.delivery_type == 3 ? false :  true "></el-input-number>
        </div>
      </div>

    </div>

    <!-- 配置信息 -->
    <div class="flex align-start mrt40">
      <div class="pdr40 edit_field flexcc bold size16">
        配置信息
      </div>
      <div class="pdl40">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="限购数量（件）">
            <el-input-number v-model="form.limit"></el-input-number>
          </el-form-item>
          <!-- 新的 -->
          <el-form-item label="投放栏目">
            <el-checkbox-group v-model="form.column_ids" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="(item,i) in citiesList" :label="item.id" :key="i.id">{{item.name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="显示配置">
            <el-checkbox v-model="form.show_time" :true-label="1" :false-label="0">是否显示购买结束时间</el-checkbox>
            <el-checkbox v-model="form.show_stock" :true-label="1" :false-label="0">是否显示剩余库存</el-checkbox>
          </el-form-item>
          <el-form-item label="上架时间">
            <el-date-picker v-model="form.timeValue" type="datetimerange" range-separator="至" @change="getTime(form.timeValue)" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="上架状态">
            <el-select v-model="form.status" placeholder="请选择">
              <el-option v-for="item in groundingStatu" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

    </div>

  </div>
</template>

<script>
import { groundingStatu } from "@/utils/statusData";
export default {
  components: {},
  props: {},
  data() {
    return {
      groundingStatu,
      citiesList: [], //投放栏目
      radio: "",
      giveawayVisible: false,
      checkListlist: "",
      checkListlist1: [],
      form: {
        delivery_price: "",
        delivery_type: "1",
        presale_type: "",
        status_start_time: "",
        status_end_time: "",
        show_time: "",
        show_stock: "",
        limit: "",
        status: 0,
        timeValue: [],
        column_ids: [],
      },
    };
  },

  created() {
    this.getGoodsColumn();
  },
  mounted() {},
  computed: {},

  methods: {
    getTime(value) {
      const [status_start_time, status_end_time] = value;
      // 对象合并 合并进params对象里
      Object.assign(this.form, {
        status_start_time,
        status_end_time,
      });
      // console.log("获取时间", this.form);
    },
    selectBox(val) {
      // console.log("拿到的值", val);
    },
    sonActive() {
      this.$emit("activefunction", 2);
    },
    next() {
      this.$emit("nextAccomplish", 0);
      // this.$emit("nextFourForm", this.form);
    },
    deliveryClick() {
      this.form.delivery_price = 0;
    },
    handleCheckedCitiesChange(val) {
      this.form.column_ids = val;
      // console.log("选择的值12312", this.form.column_ids);
    },
    handleClick(row) {},
    async getGoodsColumn() {
      const { data } = await this.$Api.animeGoodsColumn();
      // console.log("投放栏目", data);
      this.citiesList = data;
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_field {
  border-right: 1px solid black;
  height: 40px;
}
.prcire_box {
  width: 200px;
}
</style>