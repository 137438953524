<template>
  <div>
    <div class="flex align-start">
      <div class="pdr40 edit_field flexcc bold size16">
        选择分类
      </div>
      <div class=" pdl40">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="商品名称" required>
            <el-input v-model="form.name" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="商品编号">
            <el-input v-model="form.number_no" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="商品品牌：" required>
            <el-select v-model="form.brand_id" placeholder="请选择" filterable clearable>
              <el-option v-for="(item,i) in brand" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="newBrandVisible = true">新增品牌</el-button>
          </el-form-item>
          <el-form-item label="商品品牌系列：">
            <el-select v-model="form.brand_series_id" placeholder="请选择" filterable clearable>
              <el-option v-for="(item,i) in animebrand" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="seriesVisible = true">新增系列品牌</el-button>
          </el-form-item>
          <el-form-item label="商品详情介绍">
            <el-input type="textarea" v-model="form.describe" placeholder="请输入商品详情介绍" :autosize="{ minRows: 6, maxRows: 10}"></el-input>
          </el-form-item>
          <el-form-item label="商品标签">
            <el-checkbox-group v-model="form.label_ids" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="(item,i) in citiesList" :label="item.id" :key="i.id">{{item.name }}</el-checkbox>
            </el-checkbox-group>
            <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="labelVisible = true">新增标签</el-button>
          </el-form-item>
          <!-- 单张 -->
          <el-form-item label="商品封面" required>
            <tailor-pic :file='form.picture' @input='form.picture = $event'></tailor-pic>
          </el-form-item>
          <!-- 多张 -->
          <el-form-item label="商品封面图片">
            <file-list :fileList='form.chart_img' @input='imgEdit1'></file-list>
          </el-form-item>
          <el-form-item label="商品细节图">
            <file-list :fileList='form.content' @input='imgEdit'></file-list>
          </el-form-item>
        </el-form>
      </div>

    </div>

    <!-- 新增品牌  -->
    <el-dialog title="品牌" :visible.sync="newBrandVisible" width="40%">
      <el-form ref="newBrandForm" :model="newBrandForm" label-width="100px">
        <el-form-item label="品牌名称" required>
          <el-input v-model="newBrandForm.name"></el-input>
        </el-form-item>
        <el-form-item label="简介" required>
          <el-input type="textarea" v-model="newBrandForm.introduce" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
        </el-form-item>
        <el-form-item label="排序" required>
          <el-input v-model="newBrandForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="logo">
          <file-pic :file='newBrandForm.logo' @input='newBrandForm.logo = $event'></file-pic>
        </el-form-item>
        <el-form-item label="背景图片">
          <file-pic :file='newBrandForm.background_image' @input='newBrandForm.background_image = $event'></file-pic>
        </el-form-item>
        <el-form-item label="是否推荐">
          <el-select v-model="newBrandForm.is_rec" placeholder="请选择">
            <el-option v-for="item in  isrecOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="newBrandForm.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌标签" required>
          <el-select v-model="newBrandForm.label_id" placeholder="请选择品牌标签" clearable>
            <el-option v-for="item in brandLabel" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newBrandVisible = false">取 消</el-button>
        <el-button type="primary" @click="newBrandAdd()">新 增</el-button>
      </span>
    </el-dialog>

    <!-- 新增标签 -->
    <el-dialog title="商品标签" :visible.sync="labelVisible" width="40%">
      <el-form ref="labelForm" :model="labelForm" label-width="100px">
        <el-form-item label="名称" required>
          <el-input v-model="labelForm.name"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="labelForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="标签图片">
          <file-pic :file='labelForm.cover' @input='labelForm.cover = $event'></file-pic>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="labelVisible = false">取 消</el-button>
        <el-button type="primary" @click="lableAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增品牌系列 -->
    <el-dialog title="品牌系列" :visible.sync="seriesVisible" width="40%">
      <el-form ref="seriesForm" :model="seriesForm" label-width="100px">
        <el-form-item label="品牌" required>
          <el-select v-model="seriesForm.brand_id" placeholder="请选择系列品牌" clearable>
            <el-option v-for="item in brandOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" required>
          <el-input v-model="seriesForm.name"></el-input>
        </el-form-item>
        <el-form-item label="图片" required>
          <file-pic :file='seriesForm.cover' @input='seriesForm.cover = $event'></file-pic>
        </el-form-item>
        <el-form-item label="状态" required>
          <el-select v-model="seriesForm.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" required>
          <el-input v-model="seriesForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seriesVisible = false">取 消</el-button>
        <el-button type="primary" @click="brandAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// let uploadFiles = this.$refs.pictureUpload.uploadFiles;
import fileList from "@/components/common/fileList";
import filePic from "@/components/common/filePic";
import tailorPic from "@/components/common/tailorPic";

import { statusDetail } from "@/utils/statusData";

export default {
  components: { fileList, filePic, tailorPic },
  props: {},
  data() {
    return {
      dialogImage: false,
      newBrandVisible: false, //品牌
      labelVisible: false, //标签
      seriesVisible: false, //品牌系列
      pullDown: { status: 2, limit: 10000 },
      statusDetail,
      brandLabel: [],
      brandOption: [],
      animebrand: [],
      brand: [],
      dataList: [],
      citiesList: [], //标签列表
      form: {
        name: "",
        number_no: "",
        brand_id: "",
        brand_series_id: "",
        describe: "",
        label_ids: [],
        picture: "",
        content: [],
        chart_img: [],
      },
      seriesForm: {
        id: "",
        name: "",
        brand_id: "",
        cover: "",
        status: "",
        sort: "",
      },
      labelForm: {
        id: "",
        name: "",
        sort: "",
        cover: "",
      },
      newBrandForm: {
        id: "",
        name: "",
        introduce: "",
        sort: "",
        is_rec: "",
        logo: "",
        status: "",
        label_id: "",
        background_image: "",
      },
      isrecOption: [
        {
          value: 1,
          label: "推荐",
        },
        {
          value: 0,
          label: "不推荐",
        },
      ],
      active: "",
    };
  },

  created() {
    this.brandLabelList();
    this.BrandLIst();
    this.getBrand();
    this.getBrandList();
    this.getGoodLsble();
  },
  mounted() {},
  computed: {},

  methods: {
    imgEdit(val) {
      this.form.content = val;
      console.log("val", val);
      console.log("valformform", this.form);
    },
    imgEdit1(val) {
      this.form.chart_img = val;
      console.log("val", val);
      console.log("valformform", this.form);
    },
    sonActive() {
      console.log("触发上一步");
      this.active = 0;
      this.$emit("activefunction", this.active);
    },
    next() {
      console.log("第二个页面的内容", this.form);
      console.log("触发下一步");
      this.active = 2;
      this.$emit("nextfunction", this.active);
    },
    handleCheckedCitiesChange(val) {
      console.log("form.label_ids123423", this.form.label_ids);
      if (val.length >= 23) {
        this.$message.error("最多可选中23个标签");
      }
      this.form.label_ids = val;
    },
    numChange() {},
    // 新增品牌系列
    async brandAdd() {
      const data = await this.$Api.animebrandSeriesEdit(this.seriesForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.getBrandList();
      this.$message.success("新增成功");
      this.seriesVisible = false;
      this.seriesForm = {
        id: "",
        name: "",
        brand_id: "",
        cover: "",
        status: "",
        sort: "",
      };
    },
    // 新增品牌
    async newBrandAdd() {
      const data = await this.$Api.animeBrandEdit(this.newBrandForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.getBrand();
      this.$message.success("新增成功");
      this.newBrandVisible = false;
      this.newBrandForm = {
        id: "",
        name: "",
        introduce: "",
        sort: "",
        is_rec: "",
        logo: "",
        status: "",
        label_id: "",
        background_image: "",
      };
    },
    // 新增标签
    async lableAdd() {
      const data = await this.$Api.animeGgoodsLabelEdit(this.labelForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.getGoodLsble();
      this.$message.success("新增成功");
      this.labelVisible = false;
      this.labelForm = {
        id: "",
        name: "",
        sort: "",
        cover: "",
      };
    },
    async brandLabelList() {
      const { data } = await this.$Api.animeBrandLabel();
      this.brandLabel = data.list.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      // Object.assign(this.pageInfo, data);
      // console.log(data, "成功的品牌标签");
    },
    async BrandLIst() {
      const { data } = await this.$Api.animeBrand();
      this.brandOption = data.list.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      // Object.assign(this.pageInfo, data);
      // console.log(data, "成功的原著列表2131231");
    },
    // 品牌
    async getBrand() {
      const { data } = await this.$Api.animeBrand(this.pullDown);
      this.brand = data.list;
    },
    // 品牌系列
    async getBrandList() {
      const { data } = await this.$Api.animebrandSeries(this.pullDown);
      this.animebrand = data.list;
    },
    async getGoodLsble() {
      const { data } = await this.$Api.animeGgoodsLabel(this.pullDown);
      this.citiesList = data.list;
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_field {
  border-right: 1px solid black;
  height: 40px;
}
::v-deep.el-upload-list__item-thumbnail {
  /* 图片在方框内显示长边 */
  object-fit: contain !important;
}
</style>